import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class PpnTaxService {
    readPpnTax() {
        var query = `
            query {
                GetMasterTax {
                    id
                    percentage
                    created_at
                    is_default
                }
            }
        `;
        return query;
    }

    async getIsDefaultValidation() {
        var query = gql`
            query {
                GetMasterTax {
                    id
                    is_default
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var ppnTaxData = [];
        for (let i = 0; i < result.data.GetMasterTax.length; i++) {
            var data = { 
                id: result.data.GetMasterTax[i].id,
                is_default: result.data.GetMasterTax[i].is_default
            }
            ppnTaxData.push(data);
        }
        return ppnTaxData
    }

    async deleteQuery(variables){
        var query = gql`
            mutation ($id:Int!) {
                DeleteMasterTax (ID:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        var query = gql`
            mutation ($data: NewMasterTax!) {
                CreateMasterTax (New: $data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ($id:Int!, $data:NewMasterTax!) {
                UpdateMasterTax (ID:$id, New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new PpnTaxService();
